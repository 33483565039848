[class*="popup-content"] {
  width: 35%;
}
.EditProfile {
  max-width: 500px;
  max-height: 100vh;
  overflow-y: auto;
  border-radius: 12px;
  justify-content: center;
  display: flex;
  position: absolute;
  top: 100px;
  right: 0;
  left: 0;
  margin: 0 auto;
}
[class*="ReactModal__Overlay ReactModal__Overlay--after-open"] {
  background-color: rgba(0, 0, 0, 0.9) !important;
}
.sideImg {
  width: 80%;
  object-fit: contain;
  object-position: center;
  @media (max-width: 1024px) {
    width: 100%;
  }
}
.topbar {
  margin-top: -24px;
}

.activityGrid {
  display: flex;
  padding-top: 24px;
  color: #8892ad;
  line-height: 14px;
  font-size: 12px;
  font-family: "Stolzl";
}
.col {
  display: flex;
  flex: 1 1 33.33%;
  line-height: 16px;
  padding-right: 1rem;
  font-size: 12px;
}

.theme {
  color: #17a454;
  position: relative;
  &::after {
    content: "";
    max-width: 100%;
    width: 100%;
    height: 4px;
    bottom: 0;
    background: #17a454;
    position: absolute;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    justify-content: space-between;
    display: flex;
  }
}
.normal:hover {
  color: #45b676;
}

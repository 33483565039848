.TeacherActivityRowStyle {
  display: flex;
  line-height: 17px;
  color: #020422;
  padding-top: 24px;
  align-items: center;
}
.col {
  display: flex;
  flex: 1 1 33.33%;
  font-size: 14px;
  padding-right: 1rem;
  font-family: "Stolzl";
  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.EditProfile {
  max-width: 548px;
  max-height: 100vh;
  overflow-y: auto;
  border-radius: 12px;
  justify-content: center;
  background-color: aliceblue;
  display: flex;
  position: absolute;
  top: 100px;
  right: 0;
  left: 0;
  margin: 0 auto;
  @media (max-width: 768px) {
    top: 60px;
  }
  @media (max-width: 640px) {
    top: 10px;
    bottom: 10px;
  }
}
[class*="ReactModal__Overlay ReactModal__Overlay--after-open"] {
  background-color: rgba(0, 0, 0, 0.9) !important;
  background-blend-mode: color-burn !important;
}

.button {
  border-radius: 8px;
  padding: 12px;
  border-color: #67d5db;
  border-style: solid;
  color: #67d5db;
  background-color: white;
  border-width: 1px;
  font-size: 14px;
  font-family: "Stolzl";
  text-align: center;
  width: 100%;
  line-height: 16px;
  transition: all 0.3s ease-in-out;

  @media (max-width: 1024px) {
    font-size: 12px;
    padding: 10px 35px;
  }
  @media (max-width: 500px) {
    width: 100%;
  }
}
.button:hover {
  background-color: #f0fbfc;
  transition: all 0.3s ease-in-out;
}
.row {
  transition: 0.3s ease;
}

.profile {
  margin-top: -45px;
}
.img-style {
  height: 60px;
  width: 60px;
  border-radius: 12px;
  object-fit: cover;
  object-position: center;
}

.del {
  right: 70px;
  top: -394px;
}
.button {
  @media (min-width: 640px) {
    margin-right: -13px;
    margin-left: 7px;
  }
}
.uc {
  z-index: 11;
  right: 10px;
  top: -30px;
  box-shadow: 0px 10px 20px rgba(13, 75, 149, 0.1);
}
.profile {
  object-fit: cover;
  object-position: center;
  height: 40px;
  width: 40px;
  min-width: 40px;
}
.EditProfile {
  max-width: 548px;
  border-radius: 12px;
  max-height: 100vh;
  overflow-y: auto;
  justify-content: center;
  display: flex;
  position: absolute;
  top: 100px;
  right: 0;
  left: 0;
  margin: 0 auto;
  @media (max-width: 768px) {
    top: 60px;
  }
  @media (max-width: 640px) {
    top: 10px;
    bottom: 10px;
  }
}
.DeleteProfile {
  max-width: 548px;
  border-radius: 12px;
  justify-content: center;
  display: flex;
  position: absolute;
  top: 100px;
  right: 0;
  left: 0;
  margin: 0 auto;
  z-index: 2;
}

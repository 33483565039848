.activeScreen {
  color: #17a454;
  position: relative;
  &::after {
    content: "";
    max-width: 100%;
    width: 100%;
    height: 4px;
    bottom: 0;
    background: #17a454;
    position: absolute;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    display: flex;
  }
}
.normal:hover {
  color: #17a454;
}
.DeleteUsers {
  max-width: 548px;
  border-radius: 12px;
  justify-content: center;
  position: absolute;
  top: 100px;
  right: 0;
  left: 0;
  margin: 0 auto;
}

.bgImg {
  background-image: url(../../../public/images/profileBg.png);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 100px 0 0;
  color: #fff;
  border-radius: 12px;
  background-position: center;
}
.EditProfile {
  max-width: 548px;
  border-radius: 12px;
  max-height: 100vh;
  overflow-y: auto;
  justify-content: center;
  position: absolute;
  top: 100px;
  right: 0;
  left: 0;
  margin: 0 auto;
  @media (max-width: 768px) {
    top: 60px;
  }
  @media (max-width: 640px) {
    top: 10px;
    bottom: 10px;
  }
}
[class*="ReactModal__Overlay ReactModal__Overlay--after-open"] {
  background-color: rgba(0, 0, 0, 0.9) !important;
}

#root {
  background-image: linear-gradient(302.01deg, #ebf3ff 0%, #f7f9fb 161.54%);
}
.main {
  display: flex;
}
.sidenav {
  width: 290px;
}
.content {
  width: 100%;
  padding: 24px;
}
.topbar {
  position: sticky;
  top: 0;
}
.search-style {
  padding: 24px;
  background-color: white;
  margin-bottom: 24px;
  border-radius: 12px;
}
.offsetTop {
  top: 0;
}
#dashboard {
  display: flex;
  gap: 20px;
  margin: 20px;
  font-family: "Stolzl";
}
#Workshop {
  display: flex;
  gap: 20px;
  margin: 20px;
  font-family: "Stolzl";
}
#events {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin: 20px;
  font-family: "Stolzl";
}
.class-date {
  width: 37px;
  @media (max-width: "1024px") {
    width: 32px;
  }
}
#events > * {
  flex: 1 1 30%;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  font-family: "Stolzl";
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

button[disabled] {
  opacity: 0.5;
}
input:focus {
  outline: #2684ff !important;
  border-color: #2684ff;
  border-width: 1px;
}
textarea:focus {
  outline: #2684ff !important;
  border-color: #2684ff;
}
input {
  border-color: #d4e6ff;
}
input:hover {
  border-color: #2684ff;
}
textarea {
  border-color: #d4e6ff;
  border-width: 1px;
}
textarea:hover {
  border-color: #2684ff;
}

.button {
  background: #17a454;
  color: white;
  border-radius: 8px;
  width: 100%;
  padding: 16px;
  font-size: 14px;
  font-weight: 100;
  @media (max-width: 768px) {
    font-size: 12px;
  }
  &:hover {
    background: #037f39;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;
html {
  font-size: 10px;
}
body {
  margin: 0;
  font-family: "Stolzl";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.staff {
  @media (max-width: 768px) {
    margin-top: 108px;
  }
  @media (max-width: 475px) {
    margin-top: 98px;
  }
}
.normal {
  @media (max-width: 768px) {
    margin-top: 24px;
  }
  @media (max-width: 475px) {
    margin-top: 24px;
  }
}
@keyframes animation-spinner {
  100% {
    transform: rotate(360deg);
  }
}

.text {
  @media (max-width: 639px) {
    width: 245px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-left: 8px;
    padding-right: 16px;
  }
  @media (max-width: 400px) {
    width: 200px;
  }
}
.button {
  @media (min-width: 640px) {
    margin-right: 0px;
    margin-left: 14px;
  }
}
.uc {
  right: 10px;
  top: -20px;
  z-index: 11;
  box-shadow: 0px 10px 20px rgba(13, 75, 149, 0.1);
}
.buttonBg {
  background-color: rgba(13, 75, 149, 0.05);
  padding: 12px 20px;
  border-radius: 8px;
  display: flex;
  @media (max-width: 768px) {
    padding: 10px 10px;
  }
}
.content {
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.content:hover {
  background-color: rgba(2, 4, 34, 0.05);
  border-radius: 50%;
}
.img-style {
  min-width: 40px;
  width: 40px;
  object-fit: cover;
  object-position: center;
}
.icon {
  min-width: 24px;
  width: 24px;
}
.DeleteProfile {
  max-width: 548px;
  border-radius: 12px;
  justify-content: center;
  display: flex;
  position: absolute;
  top: 100px;
  right: 0;
  left: 0;
  margin: 0 auto;
}
[class*="ReactModal__Overlay ReactModal__Overlay--after-open"] {
  background-color: rgba(0, 0, 0, 0.9) !important;
}

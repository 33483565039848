.ActivityCard-Container {
  padding: 20px;
}
.rowStyle {
  display: flex;
  line-height: 16px;
  align-items: center;
  .teacherHeading {
    font-size: 14px;
    font-family: "Stolzl";
    margin-top: 0px;
    padding-left: 10px;
    margin-bottom: 4px;
    line-height: 16px;
    color: #020422;
    @media (max-width: 768px) {
      font-size: 12px;
      line-height: 15px;
    }
    @media (max-width: 640px) {
      padding-left: 0;
    }
  }
  .text {
    font-size: 12px;
    font-family: "Stolzl";
    padding-left: 10px;
    margin-top: 0;
    color: #8892ad;
    line-height: 14px;
    margin-bottom: 0;
    @media (max-width: 768px) {
      font-size: 10px;
      line-height: 13px;
    }
    @media (max-width: 640px) {
      padding-left: 0;
    }
  }
  .img-style {
    height: 40px;
    width: 40px;
    min-width: 40px;
    object-fit: cover;
    object-position: center;
    border-radius: 8px;
    @media (max-width: 475px) {
      display: none;
    }
  }
}

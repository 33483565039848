.dashboard-container {
  border-radius: 12px;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 24px;
  padding-bottom: 24px;
  @media (max-width: 1200px) and (min-width: 769px) {
    min-width: 0;
  }
}
.heading {
  font-size: 36px;
  letter-spacing: -0.01px;
  margin-bottom: 8px;
  line-height: 36px;
  font-family: "Stolzl";
  @media (max-width: 768px) {
    font-size: 34px;
    line-height: 34px;
  }
}
.text {
  font-size: 14px;
  line-height: 16px;
  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 15px;
  }
}

.yellow {
  &.dashboard-container {
    background-color: #fcf8f0;
  }
  h1 {
    color: #ffc76d;
  }
  p {
    color: #8892ad;
  }
}
.pink {
  &.dashboard-container {
    background-color: #fcf0fc;
  }
  h1 {
    color: #ff70ba;
  }
  p {
    color: #8892ad;
  }
}
.purple {
  &.dashboard-container {
    background-color: #dc91f51a;
  }
  h1 {
    color: #dc91f5;
  }
  p {
    color: #8892ad;
  }
}
.green {
  &.dashboard-container {
    background-color: #f0fcf9;
  }
  h1 {
    color: #68d5db;
  }
  p {
    color: #8892ad;
  }
}
.dashboard-teacher-container {
  border-radius: 12px;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 24px;
  padding-bottom: 24px;
  @media (max-width: 1200px) and (min-width: 769px) {
    min-width: 0;
  }
}
.yellow {
  &.dashboard-teacher-container {
    background-color: #fcf8f0;
  }
  h1 {
    color: #ffc76d;
  }
  p {
    color: #8892ad;
  }
}
.pink {
  &.dashboard-teacher-container {
    background-color: #fcf0fc;
  }
  h1 {
    color: #ff70ba;
  }
  p {
    color: #8892ad;
  }
}
.green {
  &.dashboard-teacher-container {
    background-color: #f0fcf9;
  }
  h1 {
    color: #68d5db;
  }
  p {
    color: #8892ad;
  }
}

.wrapper {
  box-shadow: 0px 10px 20px rgba(13, 75, 149, 0.1);
}
.email {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.img-style {
  height: 40px;
  width: 40px;
  min-width: 40px;
  border-radius: 12px;
  object-fit: cover;
  object-position: center;
}

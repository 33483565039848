.event-container {
  border-radius: 12px;
  overflow: hidden;
  background-color: #f7f7ff;
  min-width: 278px;
}
.title {
  font-family: "Stolzl";
  font-size: 20px;
  color: #0d4b95;
  line-height: 100%;
  display: flex;
  margin-top: 0;
  padding-top: 16px;
  padding-left: 24px;
}
.name {
  font-family: "Stolzl";
  font-size: 14px;
  padding-top: 12px;
  line-height: 114%;
  margin-top: 0;
  color: #8892ad;
  padding-left: 24px;
}
.labelWrapper {
  display: flex;
  gap: 8px;
  padding-top: 16px;
  padding-left: 24px;
}
.event {
  margin-top: 0;
}
.img-style {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 130px;
}
.dotsIcon {
  cursor: pointer;
}
.uc {
  width: 150px;
  right: 35px;
  top: 35px;
  z-index: 11;
  box-shadow: 0px 10px 20px rgba(13, 75, 149, 0.1);
}
.content {
  position: absolute;
  width: 32px;
  height: 32px;
  right: 8px;
  top: 14px;
}
.content:hover {
  background-color: rgba(2, 4, 34, 0.05);
  border-radius: 50%;
}
.draft {
  color: #ff70ba;
  font-size: 12px;
  line-height: 14px;
}
.active {
  color: #17a454;
  font-size: 12px;
  line-height: 14px;
}
.DeleteProfile {
  max-width: 548px;
  border-radius: 12px;
  justify-content: center;
  display: flex;
  position: absolute;
  top: 100px;
  right: 0;
  left: 0;
  margin: 0 auto;
}

.DeleteProfile {
  max-width: 548px;
  border-radius: 12px;
  justify-content: center;
  display: flex;
  position: absolute;
  top: 100px;
  right: 0;
  left: 0;
  margin: 0 auto;
  z-index: 2;
}

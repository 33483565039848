.row {
  padding: 24px;
  background-color: white;
  height: 100vh;
  position: sticky;
  top: 0;
  .colLink {
    display: flex;
    text-decoration: none;
    text-align: left;
    position: relative;
    .content {
      text-decoration: none;
      color: #8892ad;
    }
  }
  @media (max-width: 768px) {
    padding-top: 24px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 24px;
  }
}
.img-style:hover {
  fill: #5681b5;
}

.rowWrapper {
  display: flex;
  flex-direction: column;
  height: calc(100% - 116px);
  font-size: 14px;
  justify-content: space-between;
  @media (max-width: 1024px) {
    font-size: 11px;
  }
  @media (max-width: 768px) {
    height: 100%;
  }
}
.rowWrapper:hover {
  color: #45b676;
}
.logoutWrapper {
  display: flex;
  flex-direction: column;
  height: calc(100% - 116px);
  font-size: 14px;
  justify-content: space-between;
  padding-bottom: 24px;
}
.logoutWrapper:hover {
  color: #eb5757;
}

.content {
  color: #8892ad;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.01em;
}
.img {
  min-width: 24px;
}
.top {
  padding-top: 24px;
  padding-bottom: 24px;
  @media (max-width: 1024px) {
    padding-top: 16px;
    padding-bottom: 16px;
  }
}
.activeColor {
  .content {
    color: #17a454;
  }
  .red {
    color: #eb5757 !important;
  }
}
.activeBorder {
  position: relative;
  &::after {
    content: "";
    width: 5px;
    height: calc(100% - 40px);
    background: #17a454;
    position: absolute;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    display: flex;
    top: 50%;
    transform: translateY(-50%);
    right: -24px;
  }
}
.hoverClass {
  .content {
    color: #17a454;
    transition: all 0.3s ease-in-out;
  }
  .red {
    color: red !important;
  }
}

.logoStyle {
  padding-bottom: 28px;
  padding-top: 24px;
  width: 108px;
}

.del {
  right: 70px;
  top: -394px;
}
.button {
  @media (min-width: 640px) {
    margin-right: -13px;
    margin-left: 7px;
  }
}
.uc {
  right: 10px;
  top: -30px;
  z-index: 11;
  box-shadow: 0px 10px 20px rgba(13, 75, 149, 0.1);
}
.profile {
  object-fit: cover;
  object-position: center;
  height: 40px;
  width: 40px;
  min-width: 40px;
}
.img-cross {
  height: 30px;
  width: 30px;
  min-width: 30px;
  object-fit: cover;
  object-position: center;
  border-radius: 8px;
  object-fit: cover;
  object-position: center;
}

@font-face {
  font-family: "Stolzl";
  src: url("./fonts/Stolzl-Book.eot");
  src: local("./fonts/Stolzl Book"), local("Stolzl-Book"),
    url("./fonts/Stolzl-Book.eot?#iefix") format("embedded-opentype"),
    url("./fonts/Stolzl-Book.woff2") format("woff2"),
    url("./fonts/Stolzl-Book.woff") format("woff"),
    url("./fonts/Stolzl-Book.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Stolzl";
  src: url("./fonts/Stolzl-Regular.eot");
  src: local("./fonts/Stolzl-Regular"), local("Stolzl-Regular"),
    url("./fonts/Stolzl-Regular.eot?#iefix") format("embedded-opentype"),
    url("./fonts/Stolzl-Regular.woff2") format("woff2"),
    url("./fonts/Stolzl-Regular.woff") format("woff"),
    url("./fonts/Stolzl-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.uc {
  right: 10px;
  top: -30px;
  z-index: 11;
  box-shadow: 0px 10px 20px rgba(13, 75, 149, 0.1);
}

.content {
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  text-align: center;
}

.content:hover {
  background-color: rgba(2, 4, 34, 0.05);
  border-radius: 50%;
}

.img-style {
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 8px;
  object-fit: cover;
  object-position: center;
}

.dot-style {
  width: 25px;
  height: 25px;
}

.EditProfile {
  max-width: 548px;
  max-height: 100vh;
  overflow-y: auto;
  border-radius: 12px;
  justify-content: center;
  position: absolute;
  top: 100px;
  right: 0;
  left: 0;
  margin: 0 auto;
  @media (max-width: 768px) {
    top: 60px;
  }
  @media (max-width: 640px) {
    top: 10px;
    bottom: 10px;
  }
}

.DeleteProfile {
  max-width: 548px;
  border-radius: 12px;
  justify-content: center;
  position: absolute;
  top: 100px;
  right: 0;
  left: 0;
  margin: 0 auto;
}

.form-control {
  font-size: 20px;
}
input[type="checkbox"] {
  width: 20px;
  height: 20px;
  border-color: #d4e6ff !important;
  border-width: 1px;
}

.customstyle {
  max-width: 100%;
  border: 1px;
  border-radius: 8px;
  font-size: 12px;
  background-color: #68d5db1a;
  line-height: 15px;
  @media (max-width: 768px) {
    width: unset;
  }
  @media (min-width: 1025px) {
    width: 100%;
  }
}
.customstyle .react-calendar__month-view__days .react-calendar__tile--now {
  background: red;
}
[class*="react-calendar__tile--now"] {
  background: #0d4b95 !important;
  color: white !important;
}
[class*="react-calendar__tile--now"]:hover {
  color: black !important;
}
.customstyle {
  button .react-calendar__tile--active {
    background: red;
  }
  [class="react-calendar__viewContainer"] {
    padding: 18px 21px 18px 27px;
    [class="react-calendar__month-view "] {
      [class="react-calendar__month-view__days"] {
        [class="react-calendar__tile"] {
          background: red;
        }
      }
    }
  }
}
.react-calendar__navigation button {
  color: #6f48eb;
  min-width: 44px;
  background: none;
  font-size: 16px;
  margin-top: 8px;
}
.customstyle {
  [class="react-calendar__navigation"] {
    padding-top: 20px;
    margin-bottom: 0;
  }
}
.customstyle {
  [class="react-calendar__tile"] {
    background-color: black !important;
  }
}
.test1 .react-calendar__tile--active {
  background: black !important;
}
.react-calendar__month-view__weekdays {
  background: red;
}
[class*="react-calendar__month-view__days__day--weekend"] {
  color: black;
}
[class*="react-calendar__month-view__weekdays"] {
  text-decoration: none;
  text-transform: initial;
}
[class*="react-calendar__tile"] {
  border-radius: 8px;
}
[class*="react-calendar__tile--active"] {
  background-color: #67d5db !important;
  background: none;
  color: white;
}
[class*="react-calendar__month-view__weekdays__weekday"] {
  text-decoration: none !important;
  color: #94a3b8;
  font-size: 12px;
  & {
    abbr:where([title]) {
      text-decoration: none;
    }
  }
}
[class*="react-calendar__tile"]:enabled:hover {
  background-color: #d5f3f5 !important;
}
[class*="react-calendar__navigation"]:enabled:hover {
  background-color: #d5f3f5 !important;
  border-radius: 4px;
}
[class*="react-calendar__tile react-calendar__month-view__days__day"] {
  font-size: 14px;
}
[class*="react-calendar__navigation__label__labelText react-calendar__navigation__label__labelText--from"] {
  font-size: 14px;
}
[class*="react-calendar__tile--active"]:hover {
  color: black !important;
  background: #67d5db;
}
.test-date {
  position: relative;
}
.test-date::after {
  content: " ";
  width: 5px;
  height: 5px;
  position: absolute;
  bottom: 5px;
  left: 0;
  right: 0;
  background: #67d5db;
  margin: 0 auto;
  border-radius: 50px;
}

.content-overview {
  display: flex;
}
.dashboard-section {
  display: flex;
  gap: 24px;
  padding: 24px;
  background-color: white;
  border-radius: 12px;
}
.workshops-section {
  display: flex;
  gap: 24px;
  background-color: white;
  padding: 24px;
  margin-top: 24px;
  border-radius: 12px;
}
.activityCard-section {
  background-color: white;
  padding: 24px;
  margin-top: 24px;
  border-radius: 12px;
  margin-bottom: 24px;
}
.rightSide-section {
  background-color: white;
  padding-top: 32px;
  padding-bottom: 24px;
  border-radius: 12px;
  @media (min-width: 700px) and (max-width: 1024px) {
    min-width: 298px;
  }
  @media (min-width: 640px) and (max-width: 1024px) {
    padding-top: 0;
  }
  @media (max-width: 640px) {
    padding-top: 16px;
  }
}
.calendar-section {
  background-color: white;
  padding: 24px;
  border-radius: 12px;
  margin-bottom: 24px;
}
.wrapper {
  @media (min-width: 700px) and (max-width: 1024px) {
    gap: 24px;
  }
}
.button {
  width: 100% !important;
}

.searchbar {
  transition: 0.3s all ease-in-out;
  @media (min-width: 640px) {
    margin-right: -30px;
  }
}
@keyframes moveToRight {
  0% {
    transform: translateX(-280px);
  }
  100% {
    transform: translateX(0px);
  }
}
.mobile-active {
  @media (max-width: 768px) {
    position: fixed;
    z-index: 1;
    top: 0;
    width: calc(100% - 460px);
    margin-left: -14px;
    box-shadow: 0px 10px 20px rgba(13, 75, 149, 0.1);
  }
  @media (max-width: 640px) {
    width: 100%;
  }
}
@keyframes moveToLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-280px);
  }
}
.normal {
  @media (max-width: 768px) {
    position: absolute;
    left: -280px;
    top: -2.4rem;
    transition: all 0.1s;
  }
}
.img-style {
  height: 30px;
  width: 30px;
  min-width: 30px;
  border-radius: 8px;
  object-fit: cover;
  object-position: center;
}

.EditProfile {
  max-width: 420px;
  border-radius: 12px;
  justify-content: center;
  position: absolute;
  max-height: 100vh;
  overflow-y: auto;
  top: 100px;
  right: 0;
  left: 0;
  margin: 0 auto;
}
[class*="ReactModal__Overlay ReactModal__Overlay--after-open"] {
  background-color: rgba(0, 0, 0, 0.9) !important;
}

[class*="react-datepicker-wrapper"] {
  padding-top: 16px;
  border: 1px !important;
  border-width: 1px !important;
  border-radius: 8px;
  @media (max-width: 768px) {
    padding-top: 8px;
  }
}
[class*="react-datepicker__input-container"] input {
  padding: 22px;
  border-width: 1px;
  border-radius: 8px;
  width: 100%;
}
.DatePicker {
  [class="react-datepicker__input-container"] input {
    padding: 16px;
  }
}
.error {
  [class="react-datepicker__input-container"] input {
    padding: 16px;
    border-color: red;
  }
}
[class*="react-select__control"] {
  border-radius: 8px !important;
}
[class*="react-select__indicator-separator"] {
  display: none;
}
.cancel {
  background-color: white;
  border-width: 1px;
  border-radius: 8px;
  border-color: #037f39;
  background: white;
  color: #17a454;
  width: 100%;
  padding: 10px;
  font-size: 14px;
  font-weight: 100;
  &:hover {
    background-color: #17a4541a;
  }
}
.button {
  background: #17a454 !important;
  color: white;
  border-radius: 8px;
  width: 100%;
  padding: 16px;
  font-size: 14px;
  font-weight: 100;
  @media (max-width: 768px) {
    font-size: 12px;
  }
  &:hover {
    background: #037f39 !important;
  }
}

.textbox {
  padding: 10px 60px 10px 20px;
  width: 100%;
  text-align: left;
  border-radius: 8px;
  border: 1px;
  border-color: #cfdbea;
  box-sizing: border-box;
  border-style: solid;
}
.icon {
  position: absolute;
  border: none;
  background-color: transparent;
  height: calc(100% - 4px);
  top: 2px;
  right: 24px;
  border-radius: 12px;
}
.img-style {
  position: relative;
  width: 20px;
}
.textspan {
  position: relative;
  width: 400px;
  display: inline-block;
}
.dropdown:hover .dropdown-menu {
  display: block;
}
.button {
  height: 24px;
  border-radius: 50%;
  width: 25px;
  box-sizing: content-box;
  padding: 1rem;
  display: flex;
  justify-content: center;
  text-align: center;
  transition: all 0.5s ease-in-out;
  @media (max-width: 1200px) {
  }
}
.button:hover {
  background-color: rgba(0, 0, 0, 0.05);
  justify-content: center;
}
.MenuWrapper {
  transition: all 0.3s ease-in-out;
}
.img-style {
  height: 40px;
  width: 40px;
  border-radius: 12px;
  object-fit: cover;
  object-position: center;
}

[class*="react-datepicker-wrapper"] {
  padding-top: 16px;
  //   padding-bottom: 16px !important;
  border: 1px !important;
  border-width: 1px !important;
  border-radius: 8px;
  @media (max-width: 768px) {
    padding-top: 8px;
  }
}
[class*="react-datepicker__input-container"] input {
  padding: 22px;
  border-width: 1px;
  border-radius: 8px;
  width: 100%;
}
.DatePicker {
  [class="react-datepicker__input-container"] input {
    padding: 16px;
  }
}
.error {
  [class="react-datepicker__input-container"] input {
    padding: 16px;
    border-color: red;
  }
}
[class*="search-box__control css-1ne47d6-control:hover"] {
  border-color: #d4e6ff;
}

[class*="search-box__placeholder"] {
  font-size: 12px;
  line-height: 16px;
  color: #8892ad;
  font-family: "Stolzl";
}
.button {
  background: #17a454 !important;
  color: white;
  border-radius: 8px;
  width: 100%;
  padding: 16px;
  font-size: 14px;
  font-weight: 100;
  @media (max-width: 768px) {
    font-size: 12px;
  }
  &:hover {
    background: #037f39 !important;
  }
}

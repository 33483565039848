.due {
  display: flex;
  color: #ff70ba;
  background-color: #ff70ba1a;
  gap: 4px;
  font-size: 12px;
  border-radius: 4px;
  padding: 4px 8px;
  line-height: 14px;
  text-transform: capitalize;
}
.blue {
  background-color: #68d5db1a;
  color: #68d5db;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  line-height: 14px;
  text-transform: capitalize;
}
.completed {
  display: flex;
  gap: 4px;
  background-color: #17a4541a;
  color: #17a454;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  text-transform: capitalize;
  line-height: 14px;
}
.level {
  background-color: #68d5db1a;
  color: #68d5db;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  text-transform: capitalize;
  line-height: 14px;
}

.flex {
  display: flex;
  gap: 8px;
}

.DeleteProfile {
  max-width: 548px;
  border-radius: 12px;
  justify-content: center;
  display: flex;
  position: absolute;
  top: 100px;
  right: 0;
  left: 0;
  margin: 0 auto;
  z-index: 2;
}
[class*="ReactModal__Overlay ReactModal__Overlay--after-open"] {
  background-color: rgba(0, 0, 0, 0.9) !important;
  z-index: 111;
}

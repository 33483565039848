input:disabled {
  opacity: 0.4;
}

input[type="button"]:hover {
  transition: 0.3s all;
}
[class*="react-select__control"] {
  border-radius: 8px !important;
}
[class*="react-select__indicator-separator"] {
  display: none;
}

.button {
  background: #17a454 !important;
  color: white;
  border-radius: 12px;
  width: 100%;
  padding: 10px;
  font-size: 14px;
  font-weight: 100;
  @media (max-width: 768px) {
    font-size: 12px;
  }
  &:hover {
    background: #037f39 !important;
  }
}

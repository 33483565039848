.Workshop-container {
  border-radius: 12px;
  overflow: hidden;
  background-color: #f7f7ff;
}
.heading {
  letter-spacing: -0.01px;
  line-height: 16px;
  font-family: "Stolzl";
}
.wrapper {
  padding: 16px;
}
.img-style {
  width: 100%;
  height: 90px;
  object-fit: cover;
  object-position: center;
}
.text {
  font-size: 12px;
  line-height: 15px;
  @media (max-width: 768px) {
    font-size: 11px;
    line-height: 13px;
  }
}
.gray {
  &.Workshop-container {
    background-color: #f7f7ff;
  }
  h1 {
    color: #020422;
  }
  p {
    color: #8892ad;
  }
}

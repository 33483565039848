.bgImg {
  background-image: url(../../../public/images/wbanner1.png);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 100px 0 0;
  color: #fff;
  position: relative;
  border-radius: 12px;
  background-position: center;
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    background-color: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100%;
    border-radius: 12px;
  }
}
.img-style {
  width: 100%;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

@keyframes moveToRight {
  0% {
    transform: translateX(-280px);
  }
  100% {
    transform: translateX(0px);
  }
}
.activeDelete {
  @apply bg-bgred text-themered;
}
.activeDelete:hover {
  background-color: #eb5757;
  color: white;
}
